import * as React from 'react';
import { Box, Button, Container, Paper, TextField, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PageDefault from '../../components/PageDefault';
import api from '../../services/api';

export default function CancelCharger() {
    const [numero, setNumero] = React.useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [carregando, setCarregando] = React.useState(false);

    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const damNum = queryParams.get('numero');

    React.useEffect(() => {
        if (damNum) {
            setNumero(damNum);
        }
    }, [damNum]);

    const handleSubmit = () => {
        setOpenDialog(false);
        setCarregando(true);
        api.put(`/cobranca/cancelar/${numero}`)
            .then(async res => {
                setCarregando(false);
                toast.success(`Cobrança ${res.data.id}/${res.data.anoReferencia} cancelada com sucesso`);

                if (damNum !== null) { // se veio da pagina de cobrancas = redirect
                    navigate(-1);
                } else { // caso contrario, mantem na pagina
                    setNumero('');
                }
            })
            .catch(err => {
                setCarregando(false);
                toast.error(err.response.data.message || 'Ocorreu um erro, verifique os dados');
            })
    }

    const handleBack = () => {
        navigate(-1);
    }

    const handleOpen = e => {
        var reg = /^\d+$/;
        if (!reg.test(numero)) {
            toast.error('Número inválido');
        } else {
            setOpenDialog(true);
        }
    }

    const handleClose = () => {
        setOpenDialog(false);
    }
    return (
        <PageDefault carregando={carregando} title='Cancelamento de Cobrança'>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <React.Fragment>
                        <Grid container spacing={5} mt={1}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="numero"
                                    name="numero"
                                    label="Número"
                                    type="number"
                                    min={0}
                                    fullWidth
                                    disabled={damNum !== null}
                                    value={numero}
                                    onChange={e => setNumero(e.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={handleBack}
                                sx={{ mt: 3, ml: 1 }}>
                                Voltar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleOpen}
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Confirmar
                            </Button>
                        </Box>

                        <Dialog
                            open={openDialog}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Confirmação de cancelamento
                            </DialogTitle>
                            <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Deseja realmente cancelar a cobrança {numero}?
                            </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleClose}>Não</Button>
                            <Button onClick={handleSubmit} autoFocus>
                                Sim
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                </Paper>
            </Container>
        </PageDefault>
    );
}