import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

export default function CaixaDialogo({ open, msg, submit, setOpenDialog }) {

  return (    
    <Dialog
        open={open}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Confirmação de Ação
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {msg}
        </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
            <Button onClick={submit} autoFocus>
                Continuar
            </Button>
        </DialogActions>
    </Dialog>
  );
}