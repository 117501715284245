import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Tooltip, Button, TextField, Container, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Add, Receipt, Task } from '@mui/icons-material';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { CurrencyExchange } from '@mui/icons-material';
import { toast } from 'react-toastify';
import moment from 'moment';

import PageDefault from '../../components/PageDefault';
import api from '../../services/api';
import apiDAM from '../../services/apiDAM';

export default function Home() {
  const [contribuintes, setContribuintes] = React.useState([]);
  const [selecionados, setSelecionados] = React.useState([]);
  
  const [nameForm, setNameForm] = React.useState('');
  const [cpfForm, setCpfForm] = React.useState('');
  const [nameSearch, setNameSearch] = React.useState('');
  const [cpfSearch, setCpfSearch] = React.useState('');

  const [carregando, setCarregando] = React.useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [totalElements, setTotalElements] = React.useState(0);

  const navigate = useNavigate();

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'nome', headerName: 'Nome', width: 400 },
    { field: 'cpfCnpj', headerName: 'CPF', width: 150 },
    { field: 'placaCarro', headerName: 'Placa Veículo', width: 100 },
    { field: 'telefone', headerName: 'Telefone', width: 150 }
  ];

  // React.useEffect(() => {
  //   alert('Olá, existem imóveis sem cobrança gerada para 2023, favor verificar!');
  // }, []);

  React.useEffect(() => {
    function loadServerRows(page, nameSearch, cpfSearch) {
      setCarregando(true);
  
      let query = nameSearch !== '' ? `nome=${nameSearch}&` : '';
      query = query.concat(cpfSearch !== '' ? `cpf=${cpfSearch}&` : '');
  
      api.get(`/contribuinte?${query}page=${page}&size=${paginationModel.pageSize}`)
        .then(res => {
          const aux = [];
          res.data.content.forEach(({ id, nome, cpfCnpj, placaCarro, telefone }) => {
            const telefoneFormatado = telefone ? telefone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4") : "";
            const cpfFormatado = cpfCnpj.length === 11 ? cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.***.***-$4") : cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            const cpfSemMascara = cpfCnpj.length === 11 ? cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

            aux.push({
              id,
              nome,
              cpfCnpj: cpfFormatado,
              cpfSemMascara: cpfSemMascara,
              placaCarro,
              telefone: telefoneFormatado,
            })
          });
          setContribuintes(aux);
          setTotalElements(res.data.totalElements);
          setCarregando(false);
        })
        .catch(err => {
          if (err.response) {
            const message = err.response.data.message;
            setContribuintes([]);
            toast.error(message);
          }
          setCarregando(false);
        });
    }

    loadServerRows(paginationModel.page, nameSearch, cpfSearch)
  }, [paginationModel.page, paginationModel.pageSize, nameSearch, cpfSearch]);

  return (
    <PageDefault carregando={false} title='Contribuintes'>
      <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              id="search"
              name="search"
              label="Nome ou parte dele"
              fullWidth
              value={nameForm}
              onChange={e => setNameForm(e.target.value)}
              variant='standard'
              sx={{mr: 1}}
            />
          </Grid>
          <Grid item xs={12} sx={{mt: 1}}>
            <TextField
              id="search"
              name="search"
              label="CPF/CNPJ (apenas números)"
              fullWidth
              value={cpfForm}
              onChange={e => setCpfForm(e.target.value)}
              variant='standard'
              sx={{mr: 1}}
            />
          </Grid>
        </Grid>
        <Button 
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          startIcon={<SearchIcon />} onClick={() => {
            setNameSearch(nameForm);
            setCpfSearch(cpfForm);
            setPaginationModel({
                ...paginationModel,
                page: 0
            });
        }}>Pesquisar</Button>
      </Container>

      <div>
        <Tooltip title="Adicionar contribuinte">
          <span>
            <Button startIcon={<Add />} onClick={() => (
              navigate('/novo-contribuinte')
            )}>
              Adicionar
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Editar contribuinte">
          <span>
            <Button startIcon={<EditIcon />} disabled={selecionados.length !== 1} onClick={() => {
              navigate(`/editar-contribuinte/${selecionados[0]}`)
            }}>
              Editar
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Ver imóveis">
          <span>
            <Button startIcon={<MapsHomeWorkIcon />} disabled={selecionados.length !== 1} onClick={() => {
              navigate(`/contribuintes/${selecionados[0]}/imoveis`);
            }}>Imovéis</Button>
          </span>
        </Tooltip>
        <Tooltip title="Ver cobranças">
          <span>
            <Button startIcon={<Receipt />} disabled={selecionados.length !== 1} onClick={() => {
              navigate(`/cobrancas?contribuinte=${selecionados[0]}`);
            }}>Cobranças</Button>
          </span>
        </Tooltip>
        <Tooltip title="Emitir Cobrança">
          <span>
            <Button startIcon={<CurrencyExchange />} disabled={selecionados.length !== 1} onClick={() => {
              navigate(`/contribuintes/${selecionados[0]}/gerar-cobranca`);
            }}>Cobrar</Button>
          </span>
        </Tooltip>
        <Tooltip title="Emite Nada Consta">
          <span>
            <Button startIcon={<Task />} disabled={selecionados.length !== 1} onClick={() => {
              setCarregando(true);
              const contribuinteId = selecionados[0];
              const contribuinte = contribuintes.filter(item => (item.id === contribuinteId))[0];
              
              api.get(`/contribuinte/${contribuinteId}/nada-consta`)
              .then(res => {
                setCarregando(false);

                if (!res.data) {
                  toast.error('Contribuinte devedor de tributos, verifique as cobranças em aberto!');
                } else {
                  const payload = {
                    cpf: contribuinte.cpfSemMascara,
                    razaoSocial: contribuinte.nome,
                    validade: moment().add(60, 'days').format('DD/MM/YYYY')
                  }
                  
                  apiDAM.post('/nada-consta', payload, {
                    headers: {
                      'keyword': process.env.REACT_APP_API_DAM_KEYWORD
                    }
                  })
                    .then(res => {
                        setCarregando(false);
                        const blob = new Blob([res.data], {
                            type: 'application/pdf'
                        });
                        const url = window.URL.createObjectURL(blob);
                        window.open(url);
                    })
                    .catch(err => {
                        setCarregando(false);
                        const message = err.response.data.message;
                        toast.error(message);
                    })
                }
              })
              .catch(err => {
                setCarregando(false);
                toast.error(err.response.data.message || 'Ocorreu um erro, verifique os dados');
              });
            }}>Nada Consta</Button>
          </span>
        </Tooltip>
      </div>

      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={contribuintes}
          columns={columns}
          loading={carregando}
          paginationMode='server'
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[paginationModel.pageSize]}
          checkboxSelection
          onRowSelectionModelChange={(a) => {
            setSelecionados(a)
          }}
          rowCount={totalElements}
          //keepNonExistentRowsSelected
          localeText={{
            noRowsLabel: 'Sem contribuintes para exibir'
          }}
        />
      </Box>
    </PageDefault>
  );
}