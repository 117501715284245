import * as React from 'react';
import { Box, Button, Container, Paper, Grid, Typography, TextField, FormControl, InputLabel, MenuItem, OutlinedInput, FormHelperText } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import PageDefault from '../../components/PageDefault';
import { CurrencyMaskCustom } from '../../components/MaskedFields';
import api from '../../services/api';
import AdressForm from '../../components/AddressForm';

const enderecoPadrao = {
    'rua': '',
    'complemento': '',
    'numero': '',
    'bairro': 'Centro',
    'cidade': 'Olivêdos',
    'estado': 'PB'
};

export default function NewBuilding() {
    const [contribuinte, setContribuinte] = React.useState('');

    const [info, setInfo] = React.useState('');
    const [tipo, setTipo] = React.useState('Residencial');
    const [natureza, setNatureza] = React.useState('Construido');
    const [areaConstruida, setAreaConstruida] = React.useState('');
    const [valorM2, setValorM2] = React.useState('');
    const [numPavimentos, setNumPavimentos] = React.useState(1);
    const [planoDiretor, setPlanoDiretor] = React.useState('nao');
    const [unicoTitular, setUnicoTitular] = React.useState('sim');
    const [imovelCTN, setImovelCTN] = React.useState('nao');
    const [endereco, setEndereco] = React.useState(enderecoPadrao);
    const [carregando, setCarregando] = React.useState(false);

    const { contribuinteID, imovelID } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        setCarregando(true);

        const queryParams = new URLSearchParams(location.search);
        const fromPage = queryParams.get('from');

        api.get(`/contribuinte/${contribuinteID}`)
        .then(res => {
            setCarregando(false);
            setContribuinte(res.data.nome);
            if (fromPage) {
                // se veio da tela de cadastro de contribuinte, eu já seto o endereço
                const { rua, complemento, numero, bairro, cidade, estado } = res.data.endereco;
                setEndereco({
                    rua,
                    complemento,
                    numero,
                    bairro,
                    cidade,
                    estado
                });
            }
        })
        .catch(err => {
            setCarregando(false);
            if (err.response) {
                const message = err.response.data.message;
                toast.error(message);
            }
        });

        if (imovelID) {
            setCarregando(true);
            
            // se receber o imovelID eh o modo de edição de imóvel
            api.get(`/contribuinte/${contribuinteID}/imovel/${imovelID}`)
            .then(res => {
                const { area, natureza, endereco, imovelCTN, informacoes, isPlanoDiretor, qtdPavimento, tipo, titularUnico, valorM2 } = res.data;
                
                setInfo(informacoes);
                setTipo(tipo);
                setNatureza(natureza);
                setAreaConstruida(area.toString().replace(/[.]/g, ','));
                setValorM2(valorM2.toString().replace(/[.]/g, ','));
                setNumPavimentos(qtdPavimento);
                setPlanoDiretor(isPlanoDiretor ? 'sim' : 'nao');
                setUnicoTitular(titularUnico ? 'sim' : 'nao');
                setImovelCTN(imovelCTN ? 'sim' : 'nao');
                
                const { rua, complemento, numero, bairro, cidade, estado } = endereco;
                setEndereco({
                    rua,
                    complemento,
                    numero,
                    bairro,
                    cidade,
                    estado
                });
                setCarregando(false);                
            })
            .catch(err => {
                setCarregando(false);
                if (err.response) {
                    const message = err.response.data.message;
                    toast.error(message);
                }
            });
        }
    }, [contribuinteID, imovelID, location]);

    const isValid = () => {
        if (areaConstruida.trim() === '') {
            toast.error('Área inválida');
            return false;
        }
        if (valorM2.trim() === '') {
            toast.error('Valor(M²) inválido');
            return false;
        }
        if (endereco.rua.trim() === '') {
            toast.error('Rua inválida');
            return false;
        }
        var reg = /^\d+$/;
        if (!reg.test(endereco.numero)) {
            toast.error('Número inválido');
            return false;
        }
        if (endereco.bairro.trim() === '') {
            toast.error('Bairro inválido');
            return false;
        }
        if (endereco.cidade.trim() === '') {
            toast.error('Cidade inválida');
            return false;
        }
        if (endereco.estado.trim() === '') {
            toast.error('Estado inválido');
            return false;
        }
        return true;
    }

    const handleSubmit = e => {
        if (isValid()) {
            setCarregando(true);
            const imovelData = {
                area:           areaConstruida.replace(/[^\d,]/g, '').replace(/[,]/g, '.'),
                valorM2:        valorM2.replace(/[^\d,]/g, '').replace(/[,]/g, '.'),
                endereco,
                imovelCTN:      imovelCTN === 'sim' ? true : false,
                isPlanoDiretor: planoDiretor === 'sim' ? true : false,
                titularUnico:   unicoTitular === 'sim' ? true : false,
                natureza,
                tipo,
                informacoes:    info,
                qtdPavimento:   numPavimentos,
                dataInscricao:  "2023-03-25T23:42:35.479Z",
                valorVenal:     0
            };

            if(imovelID) {
                api.put(`/contribuinte/${contribuinteID}/imovel/${imovelID}`, imovelData)
                    .then(res => {
                        setCarregando(false);
                        toast.success('Imóvel Editado');
                        navigate(-1);
                    })
                    .catch(err => {
                        setCarregando(false);
                        toast.error(err.response.data.message || 'Ocorreu um erro, verifique os dados');
                    });
            } else {
                api.post(`/contribuinte/${contribuinteID}/imovel`, imovelData)
                    .then(res => {
                        setCarregando(false);
                        toast.success(`Imóvel Cadastrado. ID: ${res.data.id}`);
                        navigate(-1);
                    })
                    .catch(err => {
                        setCarregando(false);
                        toast.error(err.response.data.message || 'Ocorreu um erro, verifique os dados');
                    });
            }
        }
    }

    const handleBack = () => {
        navigate(-1);
    }

    const titulo = imovelID ? 'Edição de Imóvel' : 'Cadastro de Imóvel';

    return (
        <PageDefault carregando={carregando} title={titulo}>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography component="h2" variant="h6" align="center">
                        Contribuinte: {contribuinte}
                    </Typography>
                    <React.Fragment>
                        <Typography variant="h6" gutterBottom>
                            Dados do Imóvel
                        </Typography>
                        <Grid container spacing={3}>
                            {/* <Grid item xs={12}>
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="valor">Valor (R$) *</InputLabel>
                                    <Input
                                        value={valor}
                                        onChange={(e) => { setValor(e.target.value) }}
                                        id="valor"
                                        name="valor"
                                        inputComponent={CurrencyMaskCustom}
                                    />
                                </FormControl>
                            </Grid> */}

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="tipo"
                                    select
                                    fullWidth
                                    required
                                    label="Tipo"
                                    value={tipo}
                                    onChange={e => setTipo(e.target.value)}
                                >
                                    <MenuItem value='Residencial'>Residencial</MenuItem>
                                    <MenuItem value='Comercial'>Comercial</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="natureza"
                                    select
                                    fullWidth
                                    required
                                    label="Natureza"
                                    value={natureza}
                                    onChange={e => {
                                        setNatureza(e.target.value);
                                        if (e.target.value === 'Terreno')
                                            setNumPavimentos(1);
                                    }}
                                >
                                    <MenuItem value='Construido'>Construído</MenuItem>
                                    <MenuItem value='Terreno'>Terreno</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel htmlFor="areaConstruida">Área do Imóvel</InputLabel>
                                    <OutlinedInput
                                        label="Área do Imóvel"
                                        value={areaConstruida}
                                        onChange={(e) => { setAreaConstruida(e.target.value) }}
                                        id="areaConstruida"
                                        name="areaConstruida"
                                        inputComponent={CurrencyMaskCustom}
                                    />
                                    <FormHelperText>Largura x Comprimento</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel htmlFor="valorM2">Valor (M²)</InputLabel>
                                    <OutlinedInput
                                        label="Valor (M²)"
                                        value={valorM2}
                                        onChange={(e) => { setValorM2(e.target.value) }}
                                        id="valorM2"
                                        name="valorM2"
                                        inputComponent={CurrencyMaskCustom}
                                    />
                                    <FormHelperText>De acordo com a planilha</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    id="numPavimentos"
                                    name="numPavimentos"
                                    label="Número de Pavimentos"
                                    type={'number'}
                                    fullWidth
                                    disabled={natureza === 'Terreno'}
                                    helperText='Quantos pisos a construção possui'
                                    value={numPavimentos}
                                    onChange={e => {
                                        if (e.target.value >= 1)
                                            setNumPavimentos(e.target.value)}
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="plano_diretor"
                                    select
                                    fullWidth
                                    required
                                    label="Área do Plano Diretor?"
                                    value={planoDiretor}
                                    onChange={e => setPlanoDiretor(e.target.value)}
                                >
                                    <MenuItem value="sim">Sim</MenuItem>
                                    <MenuItem value="nao">Não</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="unico_titular"
                                    select
                                    fullWidth
                                    required
                                    label="Único Titular?"
                                    value={unicoTitular}
                                    onChange={e => setUnicoTitular(e.target.value)}
                                >
                                    <MenuItem value="sim">Sim</MenuItem>
                                    <MenuItem value="nao">Não</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="imovel_ctn"
                                    select
                                    fullWidth
                                    required
                                    label="Imóvel CTN?"
                                    value={imovelCTN}
                                    onChange={e => setImovelCTN(e.target.value)}
                                >
                                    <MenuItem value="sim">Sim</MenuItem>
                                    <MenuItem value="nao">Não</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="info"
                                    name="info"
                                    label="Informações Adicionais"
                                    fullWidth
                                    multiline
                                    maxRows={5}
                                    value={info}
                                    onChange={e => setInfo(e.target.value)}
                                />
                            </Grid>

                        </Grid>

                        <AdressForm endereco={endereco} setEndereco={setEndereco} />

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={handleBack}
                                sx={{ mt: 3, ml: 1 }}>
                                Voltar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Confirmar
                            </Button>
                        </Box>
                    </React.Fragment>
                </Paper>
            </Container>
        </PageDefault>
    );
}