import * as React from 'react';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, TextField, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { SystemUpdateAlt } from '@mui/icons-material';

import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';

import PageDefault from '../../components/PageDefault';
import api from '../../services/api';
import apiDAM from '../../services/apiDAM';
import { getFormatador, getYears } from '../../services/helper';

export default function ListPayments() {
    const [cobrancas, setCobrancas] = React.useState([]);
    const [mesForm, setMesForm] = React.useState(moment().format('MM'));
    const [mesSend, setMesSend] = React.useState(moment().format('MM'));
    const [anoForm, setAnoForm] = React.useState(moment().format('YYYY'));
    const [anoSend, setAnoSend] = React.useState(moment().format('YYYY'));
    const [carregando, setCarregando] = React.useState(false);
    const [paginationModel, setPaginationModel] = React.useState({
      page: 0,
      pageSize: 20,
    });
    const [totalElements, setTotalElements] = React.useState(0);
    const [openDialog, setOpenDialog] = React.useState(false);    

    React.useEffect(() => {
        setCarregando(true);
        const formatador = getFormatador();
        
        api.get(`/cobranca/pagamento?month=${mesSend}&year=${anoSend}&page=${paginationModel.page}&size=${paginationModel.pageSize}&sort=dataPagamento%2Casc`)
        .then(res => {
            const aux = [];
            res.data.content.forEach(({ cobrancaId, id, dataPagamento, formaPagamento, identificador, nome, cpfCnpj, valorPago }) => {
                aux.push({
                    _id: id,
                    cobrancaId,
                    contribuinte: nome,
                    dataPagamento: moment(dataPagamento, 'YYYY-MM-DDTh:mm:ssZ').add(3, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                    formaPagamento,
                    identificador,
                    documento: cpfCnpj.length === 11 ? cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
                    valorPago: valorPago ? formatador.format(valorPago) : ''
                })
            });
            setCobrancas(aux);
            setTotalElements(res.data.totalElements);
            setCarregando(false);
        })
        .catch(err => {
            if (err.response) {
                const message = err.response.data.message;
                toast.error(message);
            }
            setCobrancas([])
            setCarregando(false);
        });
    }, [paginationModel.page, paginationModel.pageSize, mesSend, anoSend]);

    function exportFile() {
        setOpenDialog(false);
        setCarregando(true);
        apiDAM.get(`/extrato?month=${mesSend}&year=${anoSend}`, {
            headers: {
                'Content-Type': 'application/json',
                'keyword': process.env.REACT_APP_API_DAM_KEYWORD
            }
        })
            .then(res => {
                setCarregando(false);
                const blob = new Blob([res.data], {
                    type: 'application/pdf'
                });
                const url = window.URL.createObjectURL(blob);
                window.open(url);
            })
            .catch(err => {
                setCarregando(false);
                const message = err.response.data.message;
                toast.error(message);
            })
    } 

    React.useEffect(() => {
        apiDAM.get('/')
        .then(res => {
            console.log(res.data.Status);
        })
        .catch(err => {
            console.log('Erro ao iniciar serviço');
        })
    }, []);

    const columns = [
        // { field: 'paymentId', headerName: ''},
        { field: 'cobrancaId', headerName: 'DAM', width: 80 },
        { field: 'dataPagamento', headerName: 'Data', width: 200 },
        { field: 'formaPagamento', headerName: 'Forma', width: 80 },
        { field: 'identificador', headerName: 'TXID', width: 100 },
        { field: 'contribuinte', headerName: 'Contribuinte', width: 250 },
        { field: 'documento', headerName: 'CPF/CNPJ', width: 150 },
        { field: 'valorPago', headerName: 'Valor (R$)', width: 120 }
    ];

    return (
        <PageDefault carregando={false} title='Pagamentos Recebidos'>
            <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
                <Grid container spacing={3} mt={2}>
                    <Grid item xs={6} >
                        <TextField
                            id="mesForm"
                            select
                            fullWidth
                            required
                            label="Mês"
                            value={mesForm}
                            onChange={e => {
                                setMesForm(e.target.value);
                            }}
                            disabled={carregando}
                        >
                            <MenuItem value='01'>Janeiro</MenuItem>
                            <MenuItem value='02'>Fevereiro</MenuItem>
                            <MenuItem value='03'>Março</MenuItem>
                            <MenuItem value='04'>Abril</MenuItem>
                            <MenuItem value='05'>Maio</MenuItem>
                            <MenuItem value='06'>Junho</MenuItem>
                            <MenuItem value='07'>Julho</MenuItem>
                            <MenuItem value='08'>Agosto</MenuItem>
                            <MenuItem value='09'>Setembro</MenuItem>
                            <MenuItem value='10'>Outubro</MenuItem>
                            <MenuItem value='11'>Novembro</MenuItem>
                            <MenuItem value='12'>Dezembro</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6} >
                        <TextField
                            id="anoForm"
                            select
                            fullWidth
                            required
                            label="Ano"
                            value={anoForm}
                            onChange={e => {
                                setAnoForm(e.target.value);
                            }}
                            disabled={carregando}
                        >
                            {getYears().map(ano => (
                                <MenuItem value={ano} key={ano}>{ano}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Button 
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    startIcon={<SearchIcon />} 
                    onClick={() => {
                        setMesSend(mesForm);
                        setAnoSend(anoForm);
                        setPaginationModel({
                            ...paginationModel,
                            page: 0
                        });}
                    }
                >Consultar</Button>
            </Container>
            <div>
                <Tooltip title="Exportar Relatório">
                    <span>
                        <Button startIcon={<SystemUpdateAlt />} disabled={mesForm !== mesSend || anoForm !== anoSend || cobrancas.length === 0} onClick={() => setOpenDialog(true)}>
                            Exportar
                        </Button>
                    </span>
                </Tooltip>
            </div>
            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    getRowId={(row) => row._id}
                    loading={carregando}
                    rows={cobrancas}
                    columns={columns}
                    paginationMode='server'
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[paginationModel.pageSize]}
                    rowCount={totalElements}
                    onRowSelectionModelChange={(a) => {
                        //setSelecionados(a);
                    }}
                    localeText={{
                        noRowsLabel: 'Sem pagamentos registrados'
                    }}
                />
            </Box>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmação de Ação
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Deseja realmente exportar a listagem?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Não</Button>
                    <Button onClick={exportFile} autoFocus>Sim</Button>
                </DialogActions>
            </Dialog>
        </PageDefault>
    );
}