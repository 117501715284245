import * as React from 'react';
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { Add } from '@mui/icons-material';

import PageDefault from '../../components/PageDefault';
import api from '../../services/api';

export default function ManagerStreets() {
    const [ruas, setRuas] = React.useState([]);
    const [rua, setRua] = React.useState('');
    const [bairro, setBairro] = React.useState('');
    const [cont, setCont] = React.useState(1);
    const [carregando, setCarregando] = React.useState(false);
    const [paginationModel, setPaginationModel] = React.useState({
      page: 0,
      pageSize: 20,
    });
    const [totalElements, setTotalElements] = React.useState(0);

    React.useEffect(() => {
        setCarregando(true);
        
        api.get(`/street`)
        .then(res => {
            const aux = [];
            
            res.data.content.forEach(({ id, nome, bairro }) => {
                aux.push({
                    id,
                    nome,
                    bairro
                })
            });
            setRuas(aux);
            setTotalElements(aux.length);
            setCarregando(false);
        })
        .catch(err => {
            if (err.response) {
                const message = err.response.data.message;
                toast.error(message);
            }
            setRuas([])
            setCarregando(false);
        });
    }, [paginationModel.page, paginationModel.pageSize, cont]);

    const isValid = () => {
        if (rua.trim() === '') {
            toast.error('Rua inválida');
            return false;
        }
        if (bairro.trim() === '') {
            toast.error('Bairro inválido');
            return false;
        }
        return true;
    }

    const cadastrarRua = () => {
        if (isValid()) {
            setCarregando(true);
            api.post('/street', {
                    'nome': rua.toUpperCase(),
                    'bairro': bairro.toUpperCase()
                })
                .then(res => {
                    setCarregando(false);
                    toast.success('Rua Cadastrada');
                    setPaginationModel({
                        ...paginationModel,
                        page: 0
                    });
                    setCont(cont + 1)
                    setRua('');
                    setBairro('');
                })
                .catch(err => {
                    setCarregando(false);
                    toast.error(err.response.data.message || 'Ocorreu um erro, verifique os dados');
                })
        }
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 80 },
        { field: 'nome', headerName: 'Rua', width: 500 },
        { field: 'bairro', headerName: 'Bairro', width: 150 }
    ];

    return (
        <PageDefault carregando={false} title='Gerenciar Ruas'>
            <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
                <Typography variant="h6" gutterBottom mb={-2} mt={2}>
                    Cadastro de nova rua
                </Typography>
                <Grid container spacing={3} mt={0}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="rua"
                            name="rua"
                            label="Rua"
                            fullWidth
                            value={rua}
                            onChange={e => setRua(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="bairro"
                            name="bairro"
                            fullWidth
                            required
                            label="Bairro"
                            value={bairro}
                            onChange={e => setBairro(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Button 
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    startIcon={<Add />} 
                    onClick={() => {
                        cadastrarRua();
                    }}
                >Cadastrar</Button>
            </Container>
            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    loading={carregando}
                    rows={ruas}
                    columns={columns}
                    paginationMode='client'
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[paginationModel.pageSize]}
                    rowCount={totalElements}
                    //keepNonExistentRowsSelected
                    checkboxSelection
                    onRowSelectionModelChange={(a) => {
                        //setSelecionados(a);
                    }}
                    localeText={{
                        noRowsLabel: 'Sem ruas cadastradas'
                    }}
                />
            </Box>
        </PageDefault>
    );
}