import * as React from 'react';
import { Box, Button, Container, Paper, Grid, Typography, TextField, FormControl, InputLabel, MenuItem, OutlinedInput, 
    Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import PageDefault from '../../components/PageDefault';
import { CurrencyMaskCustom } from '../../components/MaskedFields';
import api from '../../services/api';
import apiDAM from '../../services/apiDAM';
import { getFormatador, getYears } from '../../services/helper';

export default function CalcularImposto() {
    const [contribuinte, setContribuinte] = React.useState({});
    const [enderecoCobranca, setEnderecoCobranca] = React.useState({});
    const [enderecoImovel, setEnderecoImovel] = React.useState({});
    const [valorImovel, setValorImovel] = React.useState(0);
    const [valorImposto, setValorImposto] = React.useState(0);
    const [ano_ref, setAnoRef] = React.useState(new Date().getFullYear());
    const [valorJuros, setValorJuros] = React.useState('0');
    const [valorMulta, setValorMulta] = React.useState('0');
    const [isAvista, setIsAvista] = React.useState(true);
    const [comAviso, setComAviso] = React.useState('True');
    
    const [carregando, setCarregando] = React.useState(false);

    const { contribuinteID, imovelID } = useParams();

    const navigate = useNavigate();

    function handleBack() {
        navigate(-1);
    }

    function isValid() {
        if (toString(ano_ref).trim() === '') {
            toast.error('Ano de Referência inválido');
            return false;
        }
        if (valorJuros.trim() === '') {
            toast.error('Valor de Juros inválido');
            return false;
        }
        if (valorMulta.trim() === '') {
            toast.error('Valor de Multa inválido');
            return false;
        }
        return true;
    }

    function handleSubmit() {
        
        if (isValid()) {
            setCarregando(true);

            const valor_multa = valorMulta.replace(/[^\d,]/g, '').replace(/[,]/g, '.');
            const valor_juros = valorJuros.replace(/[^\d,]/g, '').replace(/[,]/g, '.');

            const dataJava = {
                'ImovelId': imovelID,
                'contribuinteId': contribuinteID,
                'anoReferencia': ano_ref,
                'mesReferencia': 12,
                'bc': valorImovel,
                'tipo': 'IPTU',
                'valorApurado': Number(valorImposto),
                'valorMulta': Number(valor_multa),
                'valorJuros': Number(valor_juros)
            }

            api.post('/cobranca', dataJava)
            .then(res => {
                const { id } = res.data;

                const dataPy = {
                    'cobrancas': [id]
                }
                apiDAM.post(`/impressao-massiva?com-aviso=${comAviso}`, dataPy, {
                    headers: {
                        'Content-Type': 'application/json',
                        'keyword': process.env.REACT_APP_API_DAM_KEYWORD
                    }
                })
                .then(res => {
                    setCarregando(false);
                    const blob = new Blob([res.data], {
                        type: 'application/pdf'
                    });
                    const url = window.URL.createObjectURL(blob);
                    window.open(url);
                })
                .catch(err => {
                    setCarregando(false);
                    const message = err.response.data.message;
                    toast.error(message);
                })
            })
            .catch(err => {
                setCarregando(false);
                const message = err.response.data.message;
                toast.error(message);
            })

        } else {
            setCarregando(false);
        }
    }

    React.useEffect(() => {

        if (contribuinteID && imovelID) {
            setCarregando(true);

            // recupera o valor do imóvel
            api.get(`/contribuinte/${contribuinteID}/imovel/${imovelID}/value`)
            .then(res => {
                setValorImovel(res.data.toFixed(2));
                setCarregando(false);                
            })
            .catch(err => {
                setCarregando(false);
                if (err.response) {
                    const message = err.response.data.message;
                    toast.error(message);
                }
            });

            // recupera o valor do iptu
            setCarregando(true);
            api.get(`/contribuinte/${contribuinteID}/imovel/${imovelID}/imposto?isAvista=${isAvista}`)
            .then(res => {
                setValorImposto(res.data.valor.toFixed(2));
                setCarregando(false);                
            })
            .catch(err => {
                setCarregando(false);
                if (err.response) {
                    const message = err.response.data.message;
                    toast.error(message);
                }
            });

            // recupera os dados do contribuinte
            setCarregando(true);
            api.get(`/contribuinte/${contribuinteID}`)
            .then(res => {
                setContribuinte(res.data);
                setEnderecoCobranca({
                    ...res.data.endereco,
                    numero: res.data.endereco.numero === 0 ? 'S/N' : res.data.endereco.numero,
                    complemento: res.data.endereco.complemento ? ` (${res.data.endereco.complemento})` : ''
                });
                setCarregando(false);
            })
            .catch(err => {
                setCarregando(false);
                if (err.response) {
                    const message = err.response.data.message;
                    toast.error(message);
                }
            });

            // recupera o endereco do imóvel
            setCarregando(true);
            api.get(`/contribuinte/${contribuinteID}/imovel/${imovelID}`)
            .then(res => {
                setEnderecoImovel({
                    ...res.data.endereco,
                    numero: res.data.endereco.numero === 0 ? 'S/N' : res.data.endereco.numero,
                    complemento: res.data.endereco.complemento ? ` (${res.data.endereco.complemento})` : ''
                });
                setCarregando(false);                
            })
            .catch(err => {
                setCarregando(false);
                if (err.response) {
                    const message = err.response.data.message;
                    toast.error(message);
                }
            });

        } else {
            navigate(-1);
        }
    }, [contribuinteID, imovelID, navigate, isAvista]);

    function formatCurrency(n) {
        const formatador = getFormatador();
        return formatador.format(n);
    }

    const rows = [{
        'tipo': 'Contribuinte',
        'valor': contribuinte.nome
    }, {
        'tipo': 'Endereço de Cobrança',
        'valor': `${enderecoCobranca.rua}, ${enderecoCobranca.numero}, ${enderecoCobranca.bairro}${enderecoCobranca.complemento}`
    }, {
        'tipo': 'Endereço de Imóvel',
        'valor': `${enderecoImovel.rua}, ${enderecoImovel.numero}, ${enderecoImovel.bairro}${enderecoImovel.complemento}`
    },{
        'tipo': 'Valor do Imóvel',
        'valor': 'R$ ' + formatCurrency(valorImovel)
    }, {
        'tipo': 'Valor do Imposto',
        'valor': 'R$ ' + formatCurrency(valorImposto)
    }];

    return (
        <PageDefault carregando={carregando} title='Geração de IPTU para Imóvel'>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                    {rows.map((row) => (
                        <TableRow
                        key={row.tipo}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="right">{row.tipo}</TableCell>
                            <TableCell align="right">{row.valor}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>

                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <React.Fragment>
                        <Typography variant="h6" gutterBottom>
                            Dados da Cobrança
                        </Typography>
                        <Grid container spacing={3}>
                            {/* <Grid item xs={12} sm={6}>
                                <TextField
                                    id="mes_ref"
                                    select
                                    fullWidth
                                    required
                                    label="Mês Referência"
                                    value={mes_ref}
                                    disabled={true}
                                    onChange={e => setMesRef(e.target.value)}
                                >
                                    <MenuItem value='01'>01</MenuItem>
                                    <MenuItem value='02'>02</MenuItem>
                                    <MenuItem value='03'>03</MenuItem>
                                    <MenuItem value='04'>04</MenuItem>
                                    <MenuItem value='05'>05</MenuItem>
                                    <MenuItem value='06'>06</MenuItem>
                                    <MenuItem value='07'>07</MenuItem>
                                    <MenuItem value='08'>08</MenuItem>
                                    <MenuItem value='09'>09</MenuItem>
                                    <MenuItem value='10'>10</MenuItem>
                                    <MenuItem value='11'>11</MenuItem>
                                    <MenuItem value='12'>12</MenuItem>
                                </TextField>
                            </Grid> */}
                            <Grid item xs={12}>
                                <TextField
                                    id="ano_ref"
                                    select
                                    fullWidth
                                    required
                                    label="Ano Referência"
                                    value={ano_ref}
                                    onChange={e => (
                                        setAnoRef(e.target.value)
                                    )}
                                >
                                    {getYears().map(ano => (
                                        <MenuItem value={ano} key={ano}>{ano}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel htmlFor="valorMulta">Valor Multa</InputLabel>
                                    <OutlinedInput
                                        label="Valor Multa"
                                        value={valorMulta}
                                        onChange={(e) => { setValorMulta(e.target.value) }}
                                        id="valorMulta"
                                        name="valorMulta"
                                        inputComponent={CurrencyMaskCustom}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel htmlFor="valorJuros">Valor Juros</InputLabel>
                                    <OutlinedInput
                                        label="Valor Juros"
                                        value={valorJuros}
                                        onChange={(e) => { setValorJuros(e.target.value) }}
                                        id="valorJuros"
                                        name="valorJuros"
                                        inputComponent={CurrencyMaskCustom}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="isAvista"
                                    select
                                    fullWidth
                                    required
                                    label="Forma de Pagamento"
                                    value={isAvista}
                                    onChange={e => (
                                        setIsAvista(e.target.value)
                                    )}
                                >
                                    <MenuItem value='true'>À Vista</MenuItem>
                                    <MenuItem value='false'>Parcelado</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="comAviso"
                                    select
                                    fullWidth
                                    required
                                    label="Com Aviso?"
                                    value={comAviso}
                                    onChange={e => (
                                        setComAviso(e.target.value)
                                    )}
                                >
                                    <MenuItem value='True'>Sim</MenuItem>
                                    <MenuItem value='False'>Não</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={handleBack}
                                sx={{ mt: 3, ml: 1 }}>
                                Voltar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Gerar DAM
                            </Button>
                        </Box>
                    </React.Fragment>
                </Paper>
            </Container>
        </PageDefault>
    );
}