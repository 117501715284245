import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, MenuItem, TextField, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { Close, Done, Print } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';

import PageDefault from '../../components/PageDefault';
import api from '../../services/api';
import apiDAM from '../../services/apiDAM';
import { getFormatador, getYears, situacao_dam, tipo_dam } from '../../services/helper';

export default function ListChargers() {
    const [cobrancas, setCobrancas] = React.useState([]);
    const [situacaoForm, setSituacaoForm] = React.useState('todas');
    const [anoForm, setAnoForm] = React.useState(moment().format('YYYY'));
    const [tipoForm, setTipoForm] = React.useState('todos');
    const [nameForm, setNameForm] = React.useState('');
    const [searchTerm, setSearchTerm] = React.useState('');
    const [carregando, setCarregando] = React.useState(false);
    const [selecionados, setSelecionados] = React.useState([]);
    const [paginationModel, setPaginationModel] = React.useState({
      page: 0,
      pageSize: 20,
    });
    const [totalElements, setTotalElements] = React.useState(0);
    const [dialogType, setDialogType] = React.useState('');
    const [dialogMsg, setDialogMsg] = React.useState('');
    const [openDialog, setOpenDialog] = React.useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const contribuinteID = queryParams.get('contribuinte');

    const callAPI = React.useCallback(() => {
        setCarregando(true);
        const formatador = getFormatador();

        let query = contribuinteID ? `contribuinteId=${contribuinteID}&` : '';
        query = query.concat(searchTerm !== '' ? `contribuinte=${searchTerm}&` : '');
        query = query.concat(situacaoForm !== 'todas' ? `situacao=${situacaoForm}&` : '');
        query = query.concat(tipoForm !== 'todos' ? `tipo=${tipoForm}&` : '');
        query = query.concat(`ano=${anoForm}&`);
        
        api.get(`/cobranca?${query}page=${paginationModel.page}&size=${paginationModel.pageSize}`)
        .then(res => {
            const aux = [];
            
            res.data.content.forEach(({ id, contribuinte, endereco, valorTotal, situacao, valorPago, tipo, numDoc }) => {

                const endereco_aux = endereco ? endereco : contribuinte.endereco;
                const numero = endereco_aux.numero === 0 ? 'S/N' : endereco_aux.numero;
                const complemento = endereco_aux.complemento ? `(${endereco_aux.complemento})` : '';

                aux.push({
                    id,
                    numDoc,
                    contribuinte: contribuinte.nome,
                    documento: contribuinte.cpfCnpj.length === 11 ? contribuinte.cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.***.***-$4") : contribuinte.cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
                    endereco: `${endereco_aux.rua}, ${numero}, ${endereco_aux.bairro} ${complemento}`,
                    valorDevido: formatador.format(valorTotal),
                    valorPago: valorPago ? formatador.format(valorPago) : '',
                    situacao,
                    tipo
                })
            });
            setCobrancas(aux);
            setTotalElements(res.data.totalElements);
            setCarregando(false);
        })
        .catch(err => {
            if (err.response) {
                const message = err.response.data.message;
                toast.error(message);
            }
            setCobrancas([])
            setCarregando(false);
        });
    }, [paginationModel.page, paginationModel.pageSize, situacaoForm, tipoForm, anoForm, contribuinteID, searchTerm])

    React.useEffect(() => {
        callAPI();
    }, [paginationModel.page, paginationModel.pageSize, situacaoForm, tipoForm, anoForm, contribuinteID, searchTerm, callAPI]);

    async function cancelChargers() {
        setOpenDialog(false);
        setCarregando(true);

        selecionados.forEach(async i => {
            const status = cobrancas.filter(item => (item.id === i))[0].situacao;
            
            if (['Aberto', 'Vencido'].includes(status)) {
                await api.put(`/cobranca/cancelar/${i}`)
                    .then(res => {
                        toast.success(`Cobrança ${res.data.id}/${res.data.anoReferencia} cancelada com sucesso`);
                    })
                    .catch(err => {
                        toast.error(err.response.data.message || 'Ocorreu um erro, verifique os dados');
                    })
            } else {
                toast.warning(`Situação da DAM ${i} é diferente de Aberto!`)
            }
        })
        setTimeout(() => {
            setCarregando(false);
            callAPI();
        }, 2000);
    }

    function printChargers(comAviso) {
        setOpenDialog(false);
        setCarregando(true);

        const paraImpressao = []

        cobrancas.forEach(item => {
            if (selecionados.includes(item.id) && ['Aberto', 'Parcial', 'Vencido'].includes(item.situacao))
                paraImpressao.push(item.id)
        });

        if (paraImpressao.length > 0) {
            const dataPy = {
                'cobrancas': paraImpressao
            }
            
            apiDAM.post(`/impressao-massiva?com-aviso=${comAviso}`, dataPy, {
                headers: {
                    'Content-Type': 'application/json',
                    'keyword': process.env.REACT_APP_API_DAM_KEYWORD
                }
            })
            .then(res => {
                setCarregando(false);
                const blob = new Blob([res.data], {
                    type: 'application/pdf'
                });
                const url = window.URL.createObjectURL(blob);
                window.open(url);
            })
            .catch(err => {
                setCarregando(false);
                const message = err.response.data.message;
                toast.error(message);
            })
        } else {
            setCarregando(false);
            toast.error('Nenhuma cobrança com valor em aberto selecionada!');
        }
    }

    const columns = [
        { field: 'id', headerName: 'DAM', width: 80 },
        { field: 'tipo', headerName: 'Tipo', width: 70 },
        { field: 'numDoc', headerName: 'N.º Doc', width: 90 },
        { field: 'contribuinte', headerName: 'Contribuinte', width: 250 },
        { field: 'endereco', headerName: 'Endereço', width: 350 },
        { field: 'valorDevido', headerName: 'Valor (R$)', width: 100 },
        { field: 'valorPago', headerName: 'Pago (R$)', width: 120 },
        { field: 'situacao', headerName: 'Status', width: 100 }
    ];

    return (
        <PageDefault carregando={false} title='Cobranças Geradas'>
            <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
                <Grid container spacing={3} mt={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="situacao"
                            select
                            fullWidth
                            required
                            label="Situação"
                            value={situacaoForm}
                            onChange={e => {
                                setSituacaoForm(e.target.value);
                                setPaginationModel({
                                    ...paginationModel,
                                    page: 0
                                })
                            }}
                            disabled={carregando}
                        >
                            {situacao_dam.map(({ value, label }) => (
                                <MenuItem value={value} key={value}>{label}</MenuItem>    
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="anoForm"
                            select
                            fullWidth
                            required
                            label="Ano Referência"
                            value={anoForm}
                            onChange={e => (
                                setAnoForm(e.target.value)
                            )}
                        >
                            {getYears().map(ano => (
                                <MenuItem value={ano} key={ano}>{ano}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="tipo"
                            select
                            fullWidth
                            required
                            label="Tipo"
                            value={tipoForm}
                            onChange={e => {
                                setTipoForm(e.target.value);
                                setPaginationModel({
                                    ...paginationModel,
                                    page: 0
                                })
                            }}
                            disabled={carregando}
                        >
                            <MenuItem value='todos'>Todos</MenuItem>
                            {tipo_dam.map(({valor, label}) => (
                                <MenuItem value={valor} key={valor}>{label}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="search"
                            name="search"
                            label="Nome do contribuinte"
                            value={nameForm}
                            onChange={e => setNameForm(e.target.value)}
                            variant='standard'
                            fullWidth
                            sx={{mr: 1}}
                            />
                    </Grid>
                </Grid>
                <Button 
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    startIcon={<SearchIcon />} onClick={() => {
                    setSearchTerm(nameForm);
                    setPaginationModel({
                        ...paginationModel,
                        page: 0
                    });
                }}>Pesquisar</Button>
            </Container>
            <div>
                <Tooltip title="Pagar cobrança">
                    <span>
                        <Button startIcon={<Done />} disabled={selecionados.length !== 1 || ['Cancelado', 'Pago'].includes(situacaoForm)} onClick={() => {
                            const status = cobrancas.filter(item => (item.id === selecionados[0]))[0].situacao;
                            if (['Parcial', 'Aberto', 'Vencido'].includes(status)) {
                                navigate(`/pagar-cobranca?numero=${selecionados[0]}`)
                            } else {
                                toast.warning('Só é possível pagar uma cobrança com valor em aberto!')
                            }
                        }}>
                            Pagar
                        </Button>
                    </span>
                </Tooltip>
                <Tooltip title="Cancelar cobrança">
                    <span>
                        <Button startIcon={<Close />} disabled={selecionados.length === 0 || ['Cancelado', 'Pago'].includes(situacaoForm)} 
                            onClick={() => {
                                setDialogType('cancelarCobranca');
                                setDialogMsg(`Deseja realmente cancelar as cobranças selecionadas ${selecionados}? *Essa ação é irreversível`);
                                setOpenDialog(true);
                        }}>
                            Cancelar
                        </Button>
                    </span>
                </Tooltip>
                <Tooltip title="Imprimir cobrança">
                    <span>
                        <Button startIcon={<Print />} disabled={selecionados.length === 0 || ['Cancelado', 'Pago'].includes(situacaoForm)} onClick={() => {
                            setDialogType('imprimirCobranca');
                            setDialogMsg(`Deseja imprimir as cobranças com aviso?`);
                            setOpenDialog(true);
                        }}>
                            Imprimir
                        </Button>
                    </span>
                </Tooltip>
            </div>
            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    loading={carregando}
                    rows={cobrancas}
                    columns={columns}
                    paginationMode='server'
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[paginationModel.pageSize]}
                    rowCount={totalElements}
                    //keepNonExistentRowsSelected
                    checkboxSelection
                    onRowSelectionModelChange={(a) => {
                        setSelecionados(a);
                    }}
                    localeText={{
                        noRowsLabel: 'Sem cobranças encontradas'
                    }}
                />
            </Box>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmação de Ação
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogMsg}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>

                    {dialogType === 'cancelarCobranca' && <div>
                        <Button onClick={cancelChargers} autoFocus>
                            Continuar
                        </Button>
                    </div>}
                    {dialogType === 'imprimirCobranca' && <div>
                        <Button onClick={() => printChargers(true)} autoFocus>
                            Sim
                        </Button>
                        <Button onClick={() => printChargers(false)}>
                            Não
                        </Button>
                    </div>}
                </DialogActions>
            </Dialog>
        </PageDefault>
    );
}