import * as React from 'react';
import { Box, Button, Container, Paper, Grid, Typography, TextField, FormControl, InputLabel, MenuItem, OutlinedInput, 
    Table, TableBody, TableCell, TableContainer, TableRow, 
    Alert} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as moment from 'moment';
import { toast } from 'react-toastify';
import 'moment/locale/pt-br';

import PageDefault from '../../components/PageDefault';
import { CurrencyMaskCustom } from '../../components/MaskedFields';
import api from '../../services/api';
import apiDAM from '../../services/apiDAM';
import { getYears, tipo_dam } from '../../services/helper';

export default function GerarCobrancaAleatoria() {
    const [contribuinte, setContribuinte] = React.useState({});
    const [endereco, setEndereco] = React.useState('');
    const [num_doc, setNumDoc] = React.useState('');
    const [tipo_imposto, setTipoImposto] = React.useState('');
    const [mes_ref, setMesRef] = React.useState(moment().format('MM'));
    const [ano_ref, setAnoRef] = React.useState(moment().format('YYYY'));
    const [valorImposto, setValorImposto] = React.useState('0');
    const [valorBc, setValorBc] = React.useState('0');
    const [valorJuros, setValorJuros] = React.useState('0');
    const [valorMulta, setValorMulta] = React.useState('0');
    const [disableTipo, setDisableTipo] = React.useState(false);
    const [disableBc, setDisableBc] = React.useState(false);
    
    const [carregando, setCarregando] = React.useState(false);

    const { contribuinteID } = useParams();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const imovelIdParam = queryParams.get('imovel-id');
    const tipoParam = queryParams.get('tipo');

    React.useEffect(() => {
        if (imovelIdParam) {
            setCarregando(true);
            // recupera o valor do imóvel
            api.get(`/contribuinte/${contribuinteID}/imovel/${imovelIdParam}/value`)
            .then(res => {
                setValorBc(res.data.toFixed(2).toString().replace(/[.]/g, ','));
                setDisableBc(true);
                setCarregando(false);
            })
            .catch(err => {
                setCarregando(false);
                if (err.response) {
                    const message = err.response.data.message;
                    toast.error(message);
                }
            });
        }

        if (tipoParam) {
            setTipoImposto(tipoParam);
            setDisableTipo(true);
        }
    }, [contribuinteID, imovelIdParam, tipoParam]);

    const navigate = useNavigate();

    function handleBack() {
        navigate(-1);
    }

    function isValid() {
        if (mes_ref.trim() === '') {
            toast.error('Mês de Referência inválido');
            return false;
        }
        if (toString(ano_ref).trim() === '') {
            toast.error('Ano de Referência inválido');
            return false;
        }
        if (tipo_imposto.trim() === '') {
            toast.error('Tipo de Cobrança inválida');
            return false;
        }
        if (valorImposto.trim() === '' || valorImposto.trim() === '0') {
            toast.error('Valor inválido');
            return false;
        }
        if (valorBc.trim() === '' || valorBc.trim() === '0') {
            toast.error('Valor BC inválido');
            return false;
        }
        if (valorJuros.trim() === '') {
            toast.error('Valor de Juros inválido');
            return false;
        }
        if (valorMulta.trim() === '') {
            toast.error('Valor de Multa inválido');
            return false;
        }
        return true;
    }

    React.useEffect(() => {
        const valor_bc = valorBc.replace(/[^\d,]/g, '').replace(/[,]/g, '.');

        if (tipo_imposto === 'ISSQN') {
            const a = Number(valor_bc) * 0.05        
            setValorImposto(a.toString().replace(/[.]/g, ','));
        } else if (tipo_imposto === 'ITIV') {
            const a = Number(valor_bc) * 0.02
            setValorImposto(a.toString().replace(/[.]/g, ','));
        } else {
            setValorImposto("0".replace(/[.]/g, ','));
        }
        
    }, [valorBc, tipo_imposto]);

    function handleSubmit() {
        if (isValid()) {
            setCarregando(true);

            const valor_imposto = valorImposto.replace(/[^\d,]/g, '').replace(/[,]/g, '.');
            const valor_multa = valorMulta.replace(/[^\d,]/g, '').replace(/[,]/g, '.');
            const valor_juros = valorJuros.replace(/[^\d,]/g, '').replace(/[,]/g, '.');
            const valor_bc = valorBc.replace(/[^\d,]/g, '').replace(/[,]/g, '.');

            const dataJava = {
                "contribuinteId": contribuinteID,
                "mesReferencia": mes_ref,
                "anoReferencia": ano_ref,
                "bc": valor_bc,
                "numDoc": num_doc,
                "tipo": tipo_imposto,
                "valorApurado": valor_imposto,
                "valorJuros": valor_juros,
                "valorMulta": valor_multa,
                "ImovelId": imovelIdParam
            }

            if (dataJava.numDoc === '')
                delete dataJava.numDoc

            api.post('/cobranca', dataJava)
            .then(res => {
                const { id } = res.data;

                const dataPy = {
                    'cobrancas': [id]
                }
                apiDAM.post(`/impressao-massiva?com-aviso=False`, dataPy, {
                    headers: {
                        'Content-Type': 'application/json',
                        'keyword': process.env.REACT_APP_API_DAM_KEYWORD
                    }
                })
                .then(res => {
                    setCarregando(false);
                    const blob = new Blob([res.data], {
                        type: 'application/pdf'
                    });
                    const url = window.URL.createObjectURL(blob);
                    window.open(url);
                })
                .catch(err => {
                    setCarregando(false);
                    const message = err.response.data.message;
                    toast.error(message);
                })
            })
            .catch(err => {
                setCarregando(false);
                const message = err.response.data.message;
                toast.error(message);
            })
        }
    }

    React.useEffect(() => {

        if (contribuinteID) {
            // recupera os dados do contribuinte
            setCarregando(true);
            api.get(`/contribuinte/${contribuinteID}`)
            .then(res => {
                setContribuinte(res.data);
                let enderecoCobranca = res.data.endereco;
                enderecoCobranca.numero = enderecoCobranca.numero === 0 ? 'S/N' : enderecoCobranca.numero;
                setEndereco(enderecoCobranca.rua + ', ' + enderecoCobranca.numero + ', ' + enderecoCobranca.bairro + ', ' + enderecoCobranca.cidade + '/' + enderecoCobranca.estado)
                setCarregando(false);
            })
            .catch(err => {
                setCarregando(false);
                if (err.response) {
                    const message = err.response.data.message;
                    toast.error(message);
                }
            });
        } else {
            navigate(-1);
        }
    }, [contribuinteID, navigate]);

    const rows = [{
        'tipo': 'Contribuinte',
        'valor': contribuinte.nome
    }, {
        'tipo': 'Endereço de Cobrança',
        'valor': endereco
    }];

    return (
        <PageDefault carregando={carregando} title='Geração de DAM para Contribuinte'>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                    {rows.map((row) => (
                        <TableRow
                        key={row.tipo}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="right">{row.tipo}</TableCell>
                            <TableCell align="right">{row.valor}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {tipoParam !== 'ITIV' && 
            <Alert severity="warning" sx={{mt: 2}}>
                Para cobrança de ITBI entre na página de Imóveis, selecione o imóvel e clique em ITBI
            </Alert>}

            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>

                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <React.Fragment>
                        <Typography variant="h6" gutterBottom>
                            Dados da Cobrança
                        </Typography>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <TextField
                                    id="ano_ref"
                                    select
                                    fullWidth
                                    required
                                    label="Tipo"
                                    disabled={disableTipo}
                                    value={tipo_imposto}
                                    onChange={e => {
                                        setTipoImposto(e.target.value);
                                        // const a = tipo.filter(i => i.label === e.target.value);
                                        // setTipoImpostoTxid(a[0].valor);
                                    }}
                                >
                                    {tipo_dam.map(({ label, valor }) => {
                                        if (valor !== 'ITIV') {
                                            return <MenuItem value={valor} key={valor}>{label}</MenuItem>
                                        } else if (tipoParam === 'ITIV') {
                                            return <MenuItem value={valor} key={valor}>{label}</MenuItem>
                                        }
                                        return null;
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="numDoc"
                                    name="numDoc"
                                    label="Número do DOC."
                                    fullWidth
                                    helperText="Caso mantenha vazio será gerado um número aleatório"
                                    value={num_doc}
                                    onChange={e => setNumDoc(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="mes_ref"
                                    select
                                    fullWidth
                                    required
                                    label="Mês Referência"
                                    value={mes_ref}
                                    onChange={e => setMesRef(e.target.value)}
                                >
                                    <MenuItem value='01'>01</MenuItem>
                                    <MenuItem value='02'>02</MenuItem>
                                    <MenuItem value='03'>03</MenuItem>
                                    <MenuItem value='04'>04</MenuItem>
                                    <MenuItem value='05'>05</MenuItem>
                                    <MenuItem value='06'>06</MenuItem>
                                    <MenuItem value='07'>07</MenuItem>
                                    <MenuItem value='08'>08</MenuItem>
                                    <MenuItem value='09'>09</MenuItem>
                                    <MenuItem value='10'>10</MenuItem>
                                    <MenuItem value='11'>11</MenuItem>
                                    <MenuItem value='12'>12</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="ano_ref"
                                    select
                                    fullWidth
                                    required
                                    label="Ano Referência"
                                    value={ano_ref}
                                    onChange={e => (
                                        setAnoRef(e.target.value)
                                    )}
                                >
                                    {getYears().map(ano => (
                                        <MenuItem value={ano} key={ano}>{ano}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel htmlFor="valorJuros">BC</InputLabel>
                                    <OutlinedInput
                                        label="BC"
                                        disabled={disableBc}
                                        value={valorBc}
                                        onChange={(e) => { setValorBc(e.target.value) }}
                                        id="valor"
                                        name="valor"
                                        inputComponent={CurrencyMaskCustom}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel htmlFor="valorJuros">Valor</InputLabel>
                                    <OutlinedInput
                                        label="Valor"
                                        value={valorImposto}
                                        onChange={(e) => { setValorImposto(e.target.value) }}
                                        id="valor"
                                        name="valor"
                                        inputComponent={CurrencyMaskCustom}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel htmlFor="valorMulta">Valor Multa</InputLabel>
                                    <OutlinedInput
                                        label="Valor Multa"
                                        value={valorMulta}
                                        onChange={(e) => { setValorMulta(e.target.value) }}
                                        id="valorMulta"
                                        name="valorMulta"
                                        inputComponent={CurrencyMaskCustom}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel htmlFor="valorJuros">Valor Juros</InputLabel>
                                    <OutlinedInput
                                        label="Valor Juros"
                                        value={valorJuros}
                                        onChange={(e) => { setValorJuros(e.target.value) }}
                                        id="valorJuros"
                                        name="valorJuros"
                                        inputComponent={CurrencyMaskCustom}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={handleBack}
                                sx={{ mt: 3, ml: 1 }}>
                                Voltar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Gerar DAM
                            </Button>
                        </Box>
                    </React.Fragment>
                </Paper>
            </Container>
        </PageDefault>
    );
}