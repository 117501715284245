import * as React from 'react';
import { Box, Tooltip, Button, Container, Grid, MenuItem, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { SystemUpdateAlt } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import moment from 'moment';

import PageDefault from '../../components/PageDefault';
import api from '../../services/api';
import apiDAM from '../../services/apiDAM';
import { getFormatador, getYears } from '../../services/helper';

export default function ContribuintesInad() {
  const [contribuintes, setContribuintes] = React.useState([]);
  // const [selecionados, setSelecionados] = React.useState([]);
  const [carregando, setCarregando] = React.useState(false);
  const [abertura, setAbertura] = React.useState(true);
  const [anoForm, setAnoForm] = React.useState(moment().format('YYYY') - 1);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [totalElements, setTotalElements] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);

  const columns = [
    { field: 'id', headerName: 'DAM', width: 80 },
    { field: 'vencimento', headerName: 'Vencimento', width: 100 },
    { field: 'guia', headerName: 'Guia', width: 100 },
    { field: 'nome', headerName: 'Contribuinte', width: 400 },
    { field: 'cpfCnpj', headerName: 'CPF', width: 150 },
    { field: 'valor', headerName: 'Valor (R$)', width: 150 }
  ];

  const loadServerRows = React.useCallback((page) => {
    setCarregando(true);
    const formatador = getFormatador();

    api.get(`/cobranca/vencidas?year=${anoForm}&page=${page}&size=${paginationModel.pageSize}&sort=imovel.contribuinte.nome%2Casc`)
      .then(res => {
        const aux = [];
        res.data.content.forEach(({ id, contribuinte, identificador, valorApurado, dataVencimento }) => {
          const cpfCnpj = contribuinte.cpfCnpj;
          const cpfSemMascara = cpfCnpj.length === 11 ? cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

          aux.push({
            id,
            'vencimento': moment(dataVencimento, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            'guia': identificador,
            'nome': contribuinte.nome,
            'cpfCnpj': cpfSemMascara,
            'valor': valorApurado ? formatador.format(valorApurado) : ''
          })
        });
        setContribuintes(aux);
        setTotalElements(res.data.totalElements);
        setCarregando(false);
      })
      .catch(err => {
        if (err.response) {
          const message = err.response.data.message;
          setContribuintes([]);
          toast.error(message);
        }
        setCarregando(false);
      });
  }, [anoForm, paginationModel.pageSize]);

  React.useEffect(() => {
    if (!abertura) {
      loadServerRows(paginationModel.page);
    }
  }, [paginationModel.page, paginationModel.pageSize, abertura, loadServerRows]);

  const exportFile = () => {
    setOpenDialog(false);
    setCarregando(true);
     apiDAM.get(`/inadimplentes?year=${anoForm}`, {
         headers: {
             'Content-Type': 'application/json',
             'keyword': process.env.REACT_APP_API_DAM_KEYWORD
         }
     })
         .then(res => {
             setCarregando(false);
             const blob = new Blob([res.data], {
                 type: 'application/pdf'
             });
             const url = window.URL.createObjectURL(blob);
             window.open(url);
         })
         .catch(err => {
             setCarregando(false);
             const message = err.response.data.message;
             toast.error(message);
         })
  }

  React.useEffect(() => {
    apiDAM.get('/')
      .then(res => {
          console.log(res.data.Status);
      })
      .catch(err => {
          console.log('Erro ao iniciar serviço');
      })
  }, []);

  return (
    <PageDefault carregando={false} title='Contribuintes Inadimplentes'>
      <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12} >
              <TextField
                  id="anoForm"
                  select
                  fullWidth
                  required
                  label="Ano de Vencimento"
                  value={anoForm}
                  onChange={e => {
                      setAnoForm(e.target.value);
                  }}
                  disabled={carregando}
              >
                {getYears().map(ano => (
                  <MenuItem value={ano} key={ano}>{ano}</MenuItem>
                ))}                  
              </TextField>
          </Grid>
      </Grid>
        <Button 
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          startIcon={<SearchIcon />} onClick={() => {
            setPaginationModel({
                ...paginationModel,
                page: 0
            });
            setAbertura(false);
            loadServerRows(0);
          }}>Consultar</Button>
      </Container>

      <div>
        <Tooltip title="Exportar Relatório">
          <span>
            <Button startIcon={<SystemUpdateAlt />} disabled={contribuintes.length === 0} onClick={() => setOpenDialog(true)}>
              Exportar
            </Button>
          </span>
        </Tooltip>
      </div>

      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={contribuintes}
          columns={columns}
          loading={carregando}
          paginationMode='server'
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[paginationModel.pageSize]}
          //checkboxSelection
          // onRowSelectionModelChange={(a) => {
          //   setSelecionados(a)
          // }}
          rowCount={totalElements}
          //keepNonExistentRowsSelected
          localeText={{
            noRowsLabel: abertura ? 'Clique em "Consultar" para atualizar a lista' : 'Sem contribuintes inadimplentes para o período'
          }}
        />
      </Box>
      <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">
              Confirmação de Ação
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente exportar a listagem?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => setOpenDialog(false)}>Não</Button>
              <Button onClick={exportFile} autoFocus>Sim</Button>
          </DialogActions>
      </Dialog>
    </PageDefault>
  );
}