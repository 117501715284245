import * as React from 'react';
import { Box, Button, Container, Paper, Grid, TextField,
    Table, TableBody, TableCell, TableContainer, TableRow, 
    Tooltip} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DoneAll from '@mui/icons-material/DoneAll';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'moment/locale/pt-br';

import PageDefault from '../../components/PageDefault';
import api from '../../services/api';
import { DataGrid } from '@mui/x-data-grid';
import CaixaDialogo from '../../components/CaixaDialogo';

export default function TransferirImovel() {
    const [contribuinte, setContribuinte] = React.useState({});
    const [novoContribuinte, setNovoContribuinte] = React.useState({});
    const [endereco, setEndereco] = React.useState('');

    const [contribuintes, setContribuintes] = React.useState([]);
    const [selecionados, setSelecionados] = React.useState([]);
    const [nameForm, setNameForm] = React.useState('');
    const [searchTerm, setSearchTerm] = React.useState('');
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const [totalElements, setTotalElements] = React.useState(0);

    const [carregando, setCarregando] = React.useState(false);

    const [openDialog, setOpenDialog] = React.useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const imovelIdParam = queryParams.get('imovel');
    const contribuinteIdParam = queryParams.get('contribuinte');

    const navigate = useNavigate();

    React.useEffect(() => {
        if (!imovelIdParam || !contribuinteIdParam) {
            navigate(-1);
        }
    }, [contribuinteIdParam, imovelIdParam, navigate]);

    React.useEffect(() => {
        function loadServerRows(page, searchName) {
          setCarregando(true);
      
          const query = searchName === '' ? '' : `nome=${searchName}&`;
      
          api.get(`/contribuinte?${query}page=${page}&size=${paginationModel.pageSize}`)
            .then(res => {
              const aux = [];
              res.data.content.forEach(({ id, nome, cpfCnpj }) => {
                const cpfFormatado = cpfCnpj.length === 11 ? cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.***.***-$4") : cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
                const cpfSemMascara = cpfCnpj.length === 11 ? cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    
                aux.push({
                  id,
                  nome,
                  cpfCnpj: cpfFormatado,
                  cpfSemMascara: cpfSemMascara
                })
              });
              setContribuintes(aux);
              setTotalElements(res.data.totalElements);
              setCarregando(false);
            })
            .catch(err => {
              if (err.response) {
                const message = err.response.data.message;
                setContribuintes([]);
                toast.error(message);
              }
              setCarregando(false);
            });
        }    
        loadServerRows(paginationModel.page, searchTerm)
      }, [paginationModel.page, paginationModel.pageSize, searchTerm]);

    function handleSubmit() {
        setOpenDialog(false);
        setCarregando(true);

        const dataJava = {
            "novoContribuinteId": novoContribuinte.id
        }

        api.put(`/contribuinte/${contribuinteIdParam}/imovel/${imovelIdParam}/transferir`, dataJava)
        .then(res => {
            setCarregando(false);
            const { id, contribuinte } = res.data;
            toast.success(`Imóvel ${id} transferido para ${contribuinte} com sucesso!`)
            navigate(-1);
        })
        .catch(err => {
            setCarregando(false);
            const message = err.response.data.message;
            toast.error(message);
        })
    }

    React.useEffect(() => {

        if (contribuinteIdParam && imovelIdParam) {
            // recupera os dados do contribuinte
            setCarregando(true);
            api.get(`/contribuinte/${contribuinteIdParam}`)
            .then(res => {
                setContribuinte(res.data);
                setCarregando(false);
            })
            .catch(err => {
                setCarregando(false);
                if (err.response) {
                    const message = err.response.data.message;
                    toast.error(message);
                }
            });

            // recupera o valor do iptu
            api.get(`/contribuinte/${contribuinteIdParam}/imovel/${imovelIdParam}`)
            .then(res => {
                let enderecoCobranca = res.data.endereco;
                enderecoCobranca.numero = enderecoCobranca.numero === 0 ? 'S/N' : enderecoCobranca.numero;
                
                setEndereco(enderecoCobranca.rua + ', ' + enderecoCobranca.numero + ', ' + enderecoCobranca.bairro + ', ' + enderecoCobranca.cidade + '/' + enderecoCobranca.estado)
                setCarregando(false);                
            })
            .catch(err => {
                setCarregando(false);
                if (err.response) {
                    const message = err.response.data.message;
                    toast.error(message);
                }
            });


        } else {
            navigate(-1);
        }
    }, [contribuinteIdParam, imovelIdParam, navigate]);

    const rows = [{
        'tipo': 'Imóvel',
        'valor': endereco
    }];

    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'nome', headerName: 'Nome', width: 400 },
        { field: 'cpfCnpj', headerName: 'CPF', width: 150 }
      ];

    return (
        <PageDefault carregando={carregando} title='Transferência de Imóvel'>

            <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                    {rows.map((row) => (
                        <TableRow
                        key={row.tipo}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="right">{row.tipo}</TableCell>
                            <TableCell align="right">{row.valor}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
                <Grid container>
                <Grid item xs={12}>
                    <TextField
                    id="search"
                    name="search"
                    label="Nome ou parte dele"
                    fullWidth
                    value={nameForm}
                    onChange={e => setNameForm(e.target.value)}
                    variant='standard'
                    sx={{mr: 1}}
                    />
                </Grid>
                </Grid>
                <Button 
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<SearchIcon />} onClick={() => {
                setSearchTerm(nameForm);
                setPaginationModel({
                    ...paginationModel,
                    page: 0
                });
                }}>Pesquisar</Button>
            </Container>

            <Tooltip title="Confirmar Trasnsferência">
                <span>
                <Button startIcon={<DoneAll />} disabled={selecionados.length !== 1 || contribuinteIdParam.toString() === selecionados[0].toString()} onClick={() => {
                    contribuintes.map(c => {
                        if (c.id === selecionados[0])
                            setNovoContribuinte(c);
                        return c.nome
                    });
                    setOpenDialog(true);
                }}>
                    Confirmar
                </Button>
                </span>
            </Tooltip>

            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                rows={contribuintes}
                columns={columns}
                loading={carregando}
                paginationMode='server'
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[paginationModel.pageSize]}
                checkboxSelection
                onRowSelectionModelChange={(a) => {
                    setSelecionados(a)
                }}
                rowCount={totalElements}
                //keepNonExistentRowsSelected
                localeText={{
                    noRowsLabel: 'Sem contribuintes para exibir'
                }}
                />
            </Box>
            <CaixaDialogo
                open={openDialog} 
                msg={`Deseja realmente transferir o imóvel de ${contribuinte.nome} para ${novoContribuinte.nome}? Lembre-se de validar o pagamento do ITBI`} 
                submit={handleSubmit}
                setOpenDialog={setOpenDialog}
            />
        </PageDefault>
    );
}