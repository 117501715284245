import React, { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { Button, CssBaseline, TextField, Link, Grid, Box, 
  Container, InputAdornment, OutlinedInput, IconButton, FormControl, InputLabel } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { toast } from 'react-toastify';

import api from '../../services/api';
import { isAuthenticated, login } from '../../services/auth';
import PageDefault from '../../components/PageDefault';

const theme = createTheme();

export default function Login(props) {
  const [apelido, setApelido] = useState('');
  const [senha, setSenha] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const [carregando, setCarregando] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectUrl = queryParams.get('redirect') ? queryParams.get('redirect') : '/';

  const isValid = () => {
    if (apelido.trim() === '') {
      toast.error('Preencha o apelido');
      return false;
    }
    if (senha.trim() === '') {
      toast.error('Preencha a senha');
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isValid()) {
      setCarregando(true);
      api.post('/user/login', {
        apelido: apelido.toLowerCase(),
        password: senha,
      })
        .then(res => {
          setCarregando(false);
          const token = res.headers.authotization;
          if (token) {
            login(token);
            navigate(redirectUrl);
          } else {
            toast.error('Token inválido!');
          }
        })
        .catch(err => {
          setCarregando(false);
          if (err.response) {
            if (err.response.status === 404) {
              toast.error('Usuário ou senha inválido!');
            } else {
              toast.error(err.response.data.message);
            }
          }
        });
    }
  };

  return (isAuthenticated() ? <Navigate to={redirectUrl} /> :
    <PageDefault carregando={carregando} title='Login'>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                fullWidth
                id="apelido"
                label="Apelido"
                name="apelido"
                autoFocus
                value={apelido}
                onChange={e => setApelido(e.target.value.toLowerCase())}
              />
              <FormControl sx={{ width: '100%' }} >
                <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={senha}
                  onChange={e => setSenha(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Senha"
                />
              </FormControl>
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Entrar
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Esqueceu a senha?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </PageDefault>
  );
}